/* These are turned into rocketship-validation objects.
 * Information on that is located in the readme for that.
 * https://github.com/rocketship-core/validator
 */
const
    range = require('lodash/range'),
    copydeck = require('rocketship-copydeck'),
    isEmail = require('validator/lib/isEmail'),
    lifecycle = require('rocketship-lifecycle'),
    moment = require('moment'),
    config = require('rocketship-config'),
    isLive = false,
    { maxFields, displayCAConfirm } = {"_public":true,"maxFields":1,"displayCAConfirm":true},
    // This is used to protect downstream systems with databases that can't handle
    // characters wider than three bytes, like FE2 and HW Analytics.
    // https://jiradc.helloworld.com/browse/SCU-144

    minChars = /^.{8,50}$/,
    minLetters = /[a-zA-Z]/,
    minNums = /[0-9]/,
    no4ByteChars = /^[\u{000000}-\u{00FFFF}]*$/u,
    noEmail = /^((?!@).)*$/,
    specialChars = /[!@#$%^&*]/,
    birthdate = /(0?[1-9]|1[0-2])-(0?[1-9]|[12][0-9]|3[01])/;

module.exports = {
    landing: {
        email: {
            required: true,
            external: [isEmail],
            no4ByteChars,
        },
    },
    login: {
        email: {
            required: true,
            external: [isEmail],
            no4ByteChars,
        },
        plainTextPassword: {
            required: true,
        },
    },
    poll: {
        pollName: {
            required: true,
        },
        pollAnswer: {
            required: true,
            async validPoll (value, field, obj) {
                const currentMonth = moment(lifecycle.now()).format('MMMM').toLowerCase();

                // Is the pollName in the current month?
                if (!obj.pollName.includes(currentMonth)) {
                    return false;
                }

                const pollCopydeck = await copydeck.get('poll');
                const answerFromCopydeck = [];

                /**
                    We don't really know the number of questions that might exist, so this
                    is a jankety way of making sure we get them all since there definitely
                    won't be more than 10. Additional entries that return no actual answer
                    work just fine.
                 */
                for (let i = 1; i <= 10; i++) {
                    answerFromCopydeck.push(pollCopydeck[obj.pollName + '_answer_' + i]);
                }

                if (answerFromCopydeck.includes(obj.pollAnswer)) {
                    return true;
                }
                else {
                    return false;
                }
            },
        },
    },
    register: {
        first_name: {
            required: true,
            no4ByteChars,
            noEmail,
        },
        last_name: {
            required: true,
            no4ByteChars,
            noEmail,
        },
        email: {
            required: true,
            external: [isEmail],
            no4ByteChars,
            checkEmail: function _checkEmail (value, key, obj, opts) {
                const validation = this;
                if (!isLive) {
                    return true;
                }

                if ((
                    /(@helloworld.com|@eprize.com|merkleinc.com|@merkle.com)/
                ).test(value)) {
                    validation.addError(key, 'ineligible_domain');
                    return false;
                }

                return true;
            },
        },
        zip: {
            required: true,
            isZip: true,
        },
        password: {
            required: true,
            passwordRequirements (value, key) {
                const requirements = { minChars, minLetters, minNums, specialChars };
                const failedReqs = Object.keys(requirements).filter(req => !value?.match(requirements[req]));

                if (failedReqs.length) {
                    failedReqs.forEach((req) => this.addError(key, req, `failed ${req} test`));
                    return false;
                }

                return true;
            },
        },
        confirm_password: {
            required: true,
            matchesField: 'password',
        },
        rules: {
            // `isChecked` on its own, like other guards, is implicitly optional, i.e.
            // it's as if we are saying "if we get a value, it must look like a checked
            // checkbox", and `required` means "we must get a value" (and `false` is a
            // value), so we use both `required` and `isChecked` to fully require this
            // field to be checked.
            required: true,
            isChecked: true,
        },
        primary_opt_in: {
            require: false,
        },
        state: {
            required: true,
        },
    },
    passwordReset: {
        password: {
            required: true,
            passwordRequirements (value, key) {
                const requirements = { minChars, minLetters, minNums, specialChars };
                const failedReqs = Object.keys(requirements).filter(req => !value?.match(requirements[req]));

                if (failedReqs.length) {
                    failedReqs.forEach((req) => this.addError(key, req, `failed ${req} test`));
                    return false;
                }

                return true;
            },
        },
        confirm_password: {
            required: true,
            matchesField: 'password',
        },
    },
    update: {
        first_name: {
            no4ByteChars,
            noEmail,
            required: true,
        },
        last_name: {
            no4ByteChars,
            noEmail,
        },
        birthdate: {
            birthdate,
        },
        address1: {
            required: false,
            no4ByteChars,
            noEmail,
        },
        address2: {
            required: false,
            no4ByteChars,
            noEmail,
        },
        city: {
            required: false,
            no4ByteChars,
            noEmail,
        },
        state: {
            required: false,
            no4ByteChars,
            noEmail,
        },
        zip: {
            isZip: true,
        },
        country: {
            required: false,
        },
        primary_opt_in: {
            required: false,
        },
    },
    birthDate: {
        month: {
            required: true,
        },
        day: {
            required: true,
        },
    },
    viral: {
        to_email1: {
            required: true,
            external: [isEmail],
            no4ByteChars,
            // These validators run on the first field, but are smart enough to check
            // for errors in all TO email addresses.
            // Note: `referringSelf` may only be validated client-side if `email` is a
            // public field in the profile config, and it's not by default.
            referringSelf (value, key, obj) {
                const
                    validation = this,
                    toEmailFields = getFilledToEmailFields(obj);

                const referredSelf = toEmailFields.find(([key, email]) => email === obj.selfEmail);

                if (referredSelf) {
                    const [selfKey] = referredSelf;
                    validation.addError(selfKey, 'REFERRED_SELF');
                    return false;
                }

                return true;
            },
            duplicateReferral (value, key, obj) {
                const
                    validation = this,
                    toEmailFields = getFilledToEmailFields(obj),
                    seen = {};

                const duplicate = toEmailFields.find(([key, email]) => {
                    if (email in seen) return true;
                    seen[email] = true;
                    return false;
                });

                if (duplicate) {
                    const [duplicateKey] = duplicate;
                    validation.addError(duplicateKey, 'DUPLICATE');
                    return false;
                }

                return true;
            },
        },
        to_name1: {
            required: true,
            no4ByteChars,
        },
    },
    faqContact: {
        first_name: {
            required: true,
        },
        email: {
            required: true,
            external: [isEmail],
        },
        question: {
            required: true,
        },
        issue_type: {
            required: true,
        },
    },
};

const viralGuards = module.exports.viral;

if (displayCAConfirm) {
    viralGuards.taf_confirm = { required: false, isChecked: true };
}

range(1, maxFields + 1).forEach((fieldNum) => {
    viralGuards['to_name' + fieldNum] = {
        ...viralGuards['to_name' + fieldNum],

        requiresField: 'to_email' + fieldNum,
    };
    viralGuards['to_email' + fieldNum] = {
        ...viralGuards['to_email' + fieldNum],

        requiresField: 'to_name' + fieldNum,
        external: [isEmail],
    };
});

function getFilledToEmailFields (obj) {
    return  Object.entries(obj)
    .filter(([key]) => key.startsWith('to_email'))
    // Filter out blanks.
    .filter(([key, email]) => !!email);
}
